"use client";

import { Button } from "@codegouvfr/react-dsfr/Button";
import { Box, Container, Typography } from "@mui/material";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import { DsfrLink } from "@/components/link/DsfrLink";
import { publicConfig } from "@/config.public";
import { NotFound } from "@/icons/NotFound";
import { ApiError } from "@/utils/api.utils";
function getErrorDescription(error: unknown): string | null {
  if (!error) {
    return null;
  }
  if (error instanceof ApiError) {
    return error.context.statusCode < 500 || publicConfig.env === "local" ? error.context.message : null;
  }
  if (publicConfig.env === "local") {
    if (error instanceof Error) {
      return error.message;
    }
    if (typeof error === "string") {
      return error;
    }
  }
  return null;
}
export default function ErrorComponent({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
    console.error(error);
  }, [error]);
  const details = getErrorDescription(error);
  return <Container maxWidth="xl" data-sentry-element="Container" data-sentry-component="ErrorComponent" data-sentry-source-file="error.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="error.tsx">
        <Box padding={8} display="flex" justifyContent="center" flexDirection="column" margin="auto" maxWidth="600px" textAlign="center" data-sentry-element="Box" data-sentry-source-file="error.tsx">
          <NotFound data-sentry-element="NotFound" data-sentry-source-file="error.tsx" />

          <Box mt={4} data-sentry-element="Box" data-sentry-source-file="error.tsx">
            <Typography variant="h1" gutterBottom data-sentry-element="Typography" data-sentry-source-file="error.tsx">
              Une erreur est survenue
            </Typography>
            {details && <Typography gutterBottom>{details}</Typography>}

            <Box mt={2} data-sentry-element="Box" data-sentry-source-file="error.tsx">
              <Button onClick={() => reset()} type="button" data-sentry-element="Button" data-sentry-source-file="error.tsx">
                Essayer à nouveau
              </Button>
            </Box>

            <Box mt={2} data-sentry-element="Box" data-sentry-source-file="error.tsx">
              <DsfrLink href="/" locale="fr" data-sentry-element="DsfrLink" data-sentry-source-file="error.tsx">
                Retourner à la page d'accueil
              </DsfrLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>;
}